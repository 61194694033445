
.button-group button  {
    width: 80px;
    height: 74px;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
    border-radius: 6px;
}

.button-group .minus {
    background-color: #C23447;
    border-color: #C23447;
}

.button-group .add {
    background-color: green;
    border-color: green;
}

.button-group > button:not(:first-child):not(:last-child) {
    margin-left: 20px;
    margin-right: 20px;
}

.score-section {
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.game-section {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}


.section {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}


.clickable {
    cursor: pointer;
}

.sidebyside {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.icon-small {
    padding-left: 5px;
    font-size: 15px;
    padding-bottom: 5px;
}

.score {
    padding-bottom: 20px;
    padding-top: 20px;
}

.icon-large {
    padding-left: 10px;
    font-size: 25px;
    padding-bottom: 3px;
}

.options > button {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    color: black;
}

.right > button {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    color: black;
}
h3 > button {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
    color: black;
}

button {
    height: 28px;
    color: black;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

input {
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

.player:not(:first-child) {
    border-top-color: gray;
    border-top-width: 1px;
    border-top-style: solid;
}

@media (max-width: 985px) {
    .score{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .positive{
        order:2;
        button {
            width: 100px;
            height: 50px;
        }
        padding-left: 20px;
    }
    .negative{
        button  {
            width: 100px;
            height: 50px;
        }
        padding-right: 5px;
        order:1;
    }
    .information{
        order:3;
        padding-bottom: 5px;
    }
    .player-score{
        font-size: 35px;
    }
}

@media (max-width: 739px) {
    .positive{
        padding-left: 0px;
        padding-right: 0px;
    }
    .negative{
        padding-left: 0px;
        padding-right: 0px;
    }
}
