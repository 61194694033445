
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: lightgray;
}

body {
  background-image: linear-gradient(black, black, black, black, #C23447);
  min-height: 100vh;
}
